/**
 * Events Language File
 * @author Brian Royer
 * Used as a common interface and API
 * between both client and the server.
 */

const ev = {
    ui: {
        CLEAR_REGISTRATION_MESSAGE: 'CLEAR_REGISTRATION_MESSAGE',
        HIDE_LOGIN_MESSAGE: 'HIDE_LOGIN_MESSAGE',
    },
    ack: {
        WELCOME: 'WELCOME',
        REGISTRATION_SUCCESS: 'REGISTRATION_SUCCESS',
        REGISTRATION_ERROR: 'REGISTRATION_ERROR',
        LOGIN_SUCCESS: 'LOGIN_SUCCESS',
        LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
        LOGIN_ERROR: 'LOGIN_ERROR',
        GET_REGISTRATION_DATA_SUCCESS: 'GET_REGISTRATION_DATA_SUCCESS',
        REQUEST_DEMO_MODE_STATUS_SUCCESS: 'REQUEST_DEMO_MODE_STATUS_SUCCESS',
        admin: {
            REQUEST_UNAPPROVED_USERS_SUCCESS:
                'REQUEST_UNAPPROVED_USERS_SUCCESS',
            CHANGE_APPROVAL_STATUS_SUCCESS: 'CHANGE_APPROVAL_STATUS_SUCCESS',
            REQUEST_APPLICATION_SETTINGS_SUCCESS: 'REQUEST_APPLICATION_SETTINGS_SUCCESS',
        },
        auth: {
            CONSULT_SUCCESS: 'CONSULT_SUCCESS',
            SUBMIT_VOTE_OPTION_SUCCESS: 'SUBMIT_VOTE_OPTION_SUCCESS',
            REQUEST_BOARD_SUCCESS: 'REQUEST_BOARD_SUCCESS',
            REQUEST_BOARD_ERROR: 'REQUEST_BOARD_ERROR',
            BOARD_OPTION_UPDATED: 'BOARD_OPTION_UPDATED',
            VOTE_FOR_OPTION_SUCCESS: 'VOTE_FOR_OPTION_SUCCESS',
            VOTE_FOR_OPTION_ERROR: 'VOTE_FOR_OPTION_ERROR',
            UNVOTE_FOR_OPTION_SUCCESS: 'UNVOTE_FOR_OPTION_SUCCESS',
            REQUEST_ALL_BOARDS_SUCCESS: 'REQUEST_ALL_BOARDS_SUCCESS',
            REQUEST_SUBSPECIALTIES_SUCCESS: 'REQUEST_SUBSPECIALTIES_SUCCESS',
            REQUEST_SUBSCRIPTION_SUCCESS: 'REQUEST_SUBSCRIPTION_SUCCESS',
        },
    },
    syn: {
        DEMO_LOGIN: 'DEMO_LOGIN',
        LOGIN: 'LOGIN',
        REGISTER: 'REGISTER',
        LOGOUT: 'LOGOUT',
        GET_REGISTRATION_DATA: 'GET_REGISTRATION_DATA',
        REQUEST_DEMO_MODE_STATUS: 'REQUEST_DEMO_MODE_STATUS',
        admin: {
            REQUEST_UNAPPROVED_USERS: 'REQUEST_UNAPPROVED_USERS',
            CHANGE_APPROVAL_STATUS: 'CHANGE_APPROVAL_STATUS',
            REQUEST_APPLICATION_SETTINGS: 'REQUEST_APPLICATION_SETTINGS',
            TOGGLE_DEMO_MODE: 'TOGGLE_DEMO_MODE',
        },
        auth: {
            SUBMIT_CONSULT: 'SUBMIT_CONSULT',
            SUBMIT_VOTE_OPTION: 'SUBMIT_VOTE_OPTION',
            REQUEST_BOARD: 'REQUEST_BOARD',
            REQUEST_ALL_BOARDS: 'REQUEST_ALL_BOARDS',
            VOTE_FOR_OPTION: 'VOTE_FOR_OPTION',
            UNVOTE_FOR_OPTION: 'UNVOTE_FOR_OPTION',
            REQUEST_SUBSPECIALTIES: 'REQUEST_SUBSPECIALTIES',
            REQUEST_SUBSCRIPTION: 'REQUEST_SUBSCRIPTION',
        },
    },
    lang: {
        en: {
            SOCKET_INITIALIZED: `Johnny 5 is alive, connections haven't short-circuited.`,
            REGISTRATION_SAVED: {
                title: 'Registration Saved',
                msg: `Thank you for your interest in our application! We sincerely cannot wait to share what we've created, hang tight while we review your request.`,
            },
            ALREADY_APPROVED: {
                title: 'Registration Approved',
                msg:
                    'This email address is already approved! Please try logging into the application.',
            },
            REGISTRATION_DECLINED: {
                title: 'Registration Declined',
                msg: 'Registration for this email address has been declined.',
            },
            REGISTRATION_EXISTS: {
                title: 'Registration Exists',
                msg:
                    'This email address is pending approval for registration. Please wait for a response.',
            },
            INVALID_USERNAME_OR_PASSWORD: {
                title: `Invalid Credentials`,
                msg: `The username or password you've supplied does not match our records. Please try again.`,
            },
            NO_ACCOUNT_FOUND: {
                title: `Invalid Account`,
                msg: `No account information could be found under the provided email. Please check the spelling or try again.`,
            },
            DUPLICATE_RECORD: {
                title: `Invalid Account`,
                msg: `This account has an issue and needs to be manually resolved. Please contact support.`,
            },
            TOKEN_EXPIRED: {
                title: `Token Expired`,
                msg: `Your secure token has expired. Please log back in to regain access.`,
            },
            USER_INITIATED: {
                title: `Logout Success`,
                msg: `Your logout has been processed successfully.`,
            },
            CONSULT_CREATED: {
                title: `Consult Created`,
                msg: `Your consult has been created. We've taken you to your new page that is awaiting answers from Pathologists.`,
            },
            BOARD_NOT_FOUND: {
                title: `Oops! This board doesn't exist.`,
                msg: `Please navigate back and try your search again.`,
            },
            BOARD_VOTE_EXISTS: {
                title: `Already Voted`,
                msg: `You've already voted for an option on this board. Try another one!`,
            },
            admin: {
                USER_EMAIL_APPROVED: (email, name) => ({
                    title: 'User Email Approved *',
                    msg: `User email ${email}, belonging to ${name} approved.`,
                }),
                USER_EMAIL_DECLINED: (email, name) => ({
                    title: 'User Email Declined *',
                    msg: `User email ${email}, belonging to ${name} declined.`,
                }),
            },
        },
    },
}

module.exports = Object.freeze(ev)
