/**
 * Socket Interface
 * @author Brian Royer
 * Converts socket events into redux events.
 * Provides some interface functions for
 * ease of use.
 */
import Registry from '../registry'

export const syn = (action, payload) => {
    const {acl} = Registry.get('store').getState()
    Registry.get('socket').emit(`syn/${action}`, {
        authorization: acl.token,
        user: acl.details,
        ...payload,
    })
}

export const ack = (action, callback) => {
    return Registry.get('socket').once(`ack/${action}`, callback)
}

export const ackForever = (action, callback) => {
    return Registry.get('socket').on(`ack/${action}`, callback)
}

export const detach = (action, callback) => {
    return Registry.get('socket').off(`ack/${action}`)
}
