import React from 'react'
import Hero from 'react-bulma-components/lib/components/hero'
import Heading from 'react-bulma-components/lib/components/heading'
import Section from 'react-bulma-components/lib/components/section'
import EditSubspecialties from './EditSubspecialties'

export default function Admin() {
    return (
        <>
            <Hero color="info" gradient>
                <Hero.Body>
                    <Section>
                        <Heading size={3}>My Settings</Heading>
                    </Section>
                </Hero.Body>
            </Hero>
            <Section>
                <EditSubspecialties />
            </Section>
        </>
    )
}
