import React from 'react'
import Button from 'react-bulma-components/lib/components/button'
import Tag from 'react-bulma-components/lib/components/tag'

import {ackForever, detach, syn} from '@services/socket'
import ev from '@source/events'

export default class UserApproval extends React.Component {
    state = {
        allUsers: [],
    }

    componentDidMount() {
        syn(ev.syn.admin.REQUEST_UNAPPROVED_USERS)
        ackForever(
            ev.ack.admin.REQUEST_UNAPPROVED_USERS_SUCCESS,
            ({allUsers}) => {
                this.setState({allUsers})
            },
        )
    }

    componentWillUnmount() {
        detach(ev.ack.admin.REQUEST_UNAPPROVED_USERS_SUCCESS)
    }

    changeApprovalStatus(email, status) {
        syn(ev.syn.admin.CHANGE_APPROVAL_STATUS, {email, status})
    }

    render() {
        return (
            <table className="table is-striped is-narrow">
                <caption>Users pending approval: {this.state.allUsers.length}</caption>
                <thead>
                    <tr>
                        <th scope="col">Email</th>
                        <th scope="col">User</th>
                        <th scope="col">School</th>
                        <th scope="col">Bio</th>
                        <th scope="col">Subspecialties</th>
                        <th scope="col" />
                    </tr>
                </thead>
                <tbody>
                    {this.state.allUsers.map(user => (
                        <tr key={`user_${user.id}`}>
                            <td data-label="Email">{user.email}</td>
                            <td data-label="Name">{user.name}</td>
                            <td data-label="School">{user.school.school}</td>
                            <td data-label="Bio">{user.bio}</td>
                            <td data-label="Subspecialties">
                                <Tag.Group style={{width: '90%'}}>
                                    {user.subspecialties.map(subspec => (
                                        <Tag
                                            key={`subspec_${subspec.id}`}
                                            color="info"
                                        >
                                            {subspec.name}
                                        </Tag>
                                    ))}
                                </Tag.Group>
                            </td>
                            <td
                                className="exclude"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                }}
                            >
                                <Button
                                    color="danger"
                                    onClick={() =>
                                        this.changeApprovalStatus(
                                            user.email,
                                            false,
                                        )
                                    }
                                >
                                    Decline
                                </Button>
                                <Button
                                    color="info"
                                    onClick={() =>
                                        this.changeApprovalStatus(
                                            user.email,
                                            true,
                                        )
                                    }
                                >
                                    Approve
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }
}
