import {combineReducers} from 'redux'
import acl from './AclReducer.js'
import board from './BoardReducer.js'
import login from './LoginReducer.js'
import room from './RoomReducer.js'
import socket from './SocketReducer.js'
import temp from './TempReducer.js'

export default combineReducers({
    acl,
    board,
    login,
    room,
    socket,
    temp,
})
