export const defaultTempState = {
    loginMessageHidden: false,
}

export default (state = defaultTempState, action) => {
    switch (action.type) {
        case 'HIDE_LOGIN_MESSAGE':
            return {
                ...state,
                loginMessageHidden: true,
            }
        default:
            return state
    }
}