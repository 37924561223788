import React from 'react'
import Hero from 'react-bulma-components/lib/components/hero'
import Heading from 'react-bulma-components/lib/components/heading'
import Section from 'react-bulma-components/lib/components/section'

import DemoMode from './DemoMode'
import UserApproval from './UserApproval'

import {ackForever, detach, syn} from '@services/socket'
import ev from '@source/events'

export default class Admin extends React.Component {

    state = {
        settings: {
            demoMode: {},
        }
    }

    componentDidMount() {
        syn(ev.syn.admin.REQUEST_APPLICATION_SETTINGS)
        ackForever(ev.ack.admin.REQUEST_APPLICATION_SETTINGS_SUCCESS, settings => {
            this.setState({settings: this.arrayToObject(settings, 'name')})
        })
    }
    
    componentWillUnmount() {
        detach(ev.ack.admin.REQUEST_APPLICATION_SETTINGS_SUCCESS)
    }

    toggleDemoMode = () => {
        syn(ev.syn.admin.TOGGLE_DEMO_MODE)
    }

    arrayToObject = (array, keyField) =>
        array.reduce((obj, item) => {
            obj[item[keyField]] = item
            delete item[keyField]
            return obj
        }, {})
    
    render() {
        return (
            <>
                <Hero color="danger" gradient>
                    <Hero.Body>
                        <Section>
                            <Heading size={3}>Administrator</Heading>
                            <Heading subtitle size={6}>
                                Tools and shortcuts
                            </Heading>
                        </Section>
                    </Hero.Body>
                </Hero>
                <Section>
                    <DemoMode demoMode={this.state.settings.demoMode} toggleDemoMode={this.toggleDemoMode} />
                    <hr />
                    <UserApproval />
                </Section>
            </>
        )
    } 
}
