export const defaultBoardState = {
    details: {},
    userVote: {},
}

function addOrReplace(array, item) {
    const i = array.findIndex(_item => _item.id === item.id)
    if (i > -1) array[i] = item
    else array.push(item)

    return array
}

export default (state = defaultBoardState, action) => {
    switch (action.type) {
        case 'ack/LOGOUT_SUCCESS':
            return defaultBoardState
        case 'ack/REQUEST_BOARD_SUCCESS':
            return {
                ...state,
                details: action.payload,
            }
        case 'ack/VOTE_FOR_OPTION_SUCCESS':
            return {
                ...state,
                userVote: action.payload,
            }
        case 'ack/UNVOTE_FOR_OPTION_SUCCESS':
            return {
                ...state,
                userVote: {},
            }
        case 'ack/BOARD_OPTION_UPDATED':
            return {
                ...state,
                details: {
                    ...state.details,
                    options: addOrReplace(
                        state.details.options,
                        action.payload,
                    ),
                },
            }
        default:
            return state
    }
}
