import React from 'react'

import Media from 'react-bulma-components/lib/components/media'
import Image from 'react-bulma-components/lib/components/image'
import Content from 'react-bulma-components/lib/components/content'
import Heading from 'react-bulma-components/lib/components/heading'

export default function UserPost({board}) {
    return board ? (
        <>
            <Media>
                <Media.Item renderas="figure" position="left">
                    <Image
                        renderas="p"
                        size={64}
                        alt="64x64"
                        src={`https://ui-avatars.com/api/?name=${
                            board.user.name
                        }`}
                    />
                </Media.Item>
                <Media.Item>
                    <Heading size={4}>{board.user.name}</Heading>
                    <Heading subtitle size={6}>
                        {board.user.school.school}
                    </Heading>
                </Media.Item>
            </Media>
            <Content>{board.description}</Content>
        </>
    ) : null
}
