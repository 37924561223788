import React from 'react'
import Drawer from 'react-drag-drawer'
import Heading from 'react-bulma-components/lib/components/heading'
import classNames from 'classnames'
import {css} from 'emotion'
import {syn} from '@services/socket'
import ev from '@source/events'

function getTextWidth(text, font) {
    var canvas =
        getTextWidth.canvas ||
        (getTextWidth.canvas = document.createElement('canvas'))
    var context = canvas.getContext('2d')
    context.font = font
    var metrics = context.measureText(text)
    return metrics.width
}
export default class Plot extends React.Component {
    state = {
        option: {},
        drawerOpen: false,
    }

    barHeight = 50

    openDrawer = option => {
        this.setState({option, drawerOpen: true})
    }

    closeDrawer = () => {
        this.setState({option: {}, drawerOpen: false})
    }

    renderDrawer = () => {
        return (
            <Drawer
                open={this.state.drawerOpen}
                onRequestClose={this.closeDrawer}
                modalElementClass={ModalElement}
            >
                <Heading size={4}>{this.state.option.title}</Heading>
                <div>{this.state.option.theory}</div>
            </Drawer>
        )
    }

    render() {
        return (
            <svg width="100%" height={this.props.options.length * 100}>
                <g className="container">
                    <g className="chart">
                        {this.renderDrawer()}
                        {this.props.options.map((boardOption, idx) => (
                            <React.Fragment key={`plot.${idx}`}>
                                <g
                                    transform={`translate(0, ${idx *
                                        this.barHeight *
                                        2})`}
                                    key={`barGroup.${idx}`}
                                >
                                    <PlotBarGroup
                                        svgWidth={window.innerWidth - 111}
                                        boardOption={boardOption}
                                        barHeight={this.barHeight}
                                        userVote={this.props.userVote}
                                        openDrawer={this.openDrawer}
                                        closeDrawer={this.closeDrawer}
                                    />
                                </g>
                            </React.Fragment>
                        ))}
                    </g>
                </g>
            </svg>
        )
    }
}

class PlotBarGroup extends React.Component {
    hasVote = () => {
        const {boardOption, userVote} = this.props
        if (boardOption.id === userVote.board_options_id) {
            return true
        }
        return false
    }

    vote(boardId, optionId) {
        syn(ev.syn.auth.VOTE_FOR_OPTION, {
            boardId,
            optionId,
        })
    }

    unvote(boardId, optionId, userVoteId) {
        syn(ev.syn.auth.UNVOTE_FOR_OPTION, {
            boardId,
            optionId,
            userVoteId,
        })
    }

    toggleVote = e => {
        e.preventDefault()
        const {boardOption, userVote} = this.props
        if (this.hasVote()) {
            this.unvote(boardOption.board_id, boardOption.id, userVote.id)
        } else {
            this.props.openDrawer(boardOption)
            this.vote(boardOption.board_id, boardOption.id)
        }
    }

    render() {
        let barPadding = 2
        let widthScale = d => {
            if (!d) return d
            const value = (d * this.props.svgWidth) / (d + 20) + 1
            return value
        }

        let width = widthScale(this.props.boardOption.votes)
        let yMid = this.props.barHeight * 0.5
        let textWidth = getTextWidth(
            this.props.boardOption.title,
            `16px Segoe UI`,
        )
        let fontSize = 1

        if (textWidth > this.props.svgWidth) {
            fontSize = (this.props.svgWidth - 60) / textWidth
        }

        const textClasess = classNames({
            'all-value': this.props.boardOption.votes > 1,
            'one-value': this.props.boardOption.votes === 1,
            'no-value': this.props.boardOption.votes === 0,
            'user-value': this.props.boardOption.votes > 1 && this.hasVote(),
        })

        const rectClasses = classNames({
            'votes-rect': true,
            'votes-rect-has-vote': this.hasVote(),
        })

        return (
            // Ignore HTML svg errors on this line, buttons don't
            // work in SVGs. Makes the whole template blank.
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a style={{width: '100%'}} href="#" onClick={this.toggleVote}>
                <text
                    className="name-label"
                    style={{
                        fontSize: `${fontSize}rem`,
                    }}
                    x={0}
                    y={this.props.barHeight / 3 - 5}
                >
                    {this.props.boardOption.title}
                </text>
                <g className="bar-group">
                    <rect
                        x={0}
                        y={this.props.barHeight / 3 + 2}
                        height={this.props.barHeight - barPadding}
                        width={this.props.svgWidth}
                        fill={'#404040'}
                    />
                    <rect
                        x={2}
                        y={this.props.barHeight / 3}
                        width={width + 2}
                        height={this.props.barHeight - barPadding}
                        className={rectClasses}
                    />
                    <text
                        className={textClasess}
                        x={
                            this.props.boardOption.votes > 1
                                ? width - 10
                                : width + 10
                        }
                        y={yMid + this.props.barHeight / 3}
                        alignmentBaseline="middle"
                    >
                        {this.props.boardOption.votes}
                    </text>
                </g>
            </a>
        )
    }
}

const modal = css`
    position: absolute;
    top: ${window.innerHeight - 120}px;

    background-color: white;
    width: 100%;
    max-width: 700px;
    min-height: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
`

const ModalElement = css`
    ${modal} text-align: left;
    padding: 1em;
    @media (max-width: 767px) {
        width: 100%;
    }
`
