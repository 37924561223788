import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import {connect} from 'react-redux'

export function PrivateRoute({component: Component, token, ...rest}) {
    return (
        <Route
            {...rest}
            render={props =>
                token ? <Component {...props} /> : <Redirect to="/login" />
            }
        />
    )
}

const mapStateToProps = state => ({
    token: state.acl.token,
})

export default connect(mapStateToProps)(PrivateRoute)
