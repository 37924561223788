import "core-js/stable";
import "regenerator-runtime/runtime";

// /src/index.js
import React from 'react'
import ReactDOM from 'react-dom'
import Layout from './components/Layout'
import * as serviceWorker from './serviceWorker'
import {Provider} from 'react-redux'
import Index from '@pages'
import Login from '@pages/Login'
import Demo from '@pages/Demo'
import Register from '@pages/Register'
import Settings from '@pages/settings'
import Admin from '@pages/admin'
import AllBoards from '@pages/board/AllBoards'
import CreateBoard from '@pages/board/CreateBoard'
import Board from '@pages/board'
import AdminRoute from './containers/AdminRoute'
import PrivateRoute from './containers/PrivateRoute'
import history from './history'
import {Router, Route, Switch} from 'react-router-dom'
import {PersistGate} from 'redux-persist/integration/react'
import Spinner from './components/Spinner'
import configureStore from './store'
import Registry from './registry'

// Styling and fonts
import './sass/app.sass'
import './font/library'

const {socket, persistor, store} = configureStore()
Registry.register('socket', socket)
Registry.register('persistor', persistor)
Registry.register('store', store)

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={<Spinner />} persistor={persistor}>
            <Router history={history}>
                <Layout>
                    <Switch>
                        <Route path="/demo" exact component={Demo} />
                        <Route path="/" exact component={Index} />
                        <Route path="/login" exact component={Login} />
                        <Route path="/register" exact component={Register} />
                        <PrivateRoute
                            path="/settings"
                            exact
                            component={Settings}
                        />
                        <AdminRoute path="/admin" exact component={Admin} />
                        <PrivateRoute
                            path="/board"
                            exact
                            component={AllBoards}
                        />
                        <PrivateRoute
                            path="/board/subspecialty/:subspecialty?"
                            exact
                            component={AllBoards}
                        />
                        <PrivateRoute
                            path="/board/create"
                            exact
                            component={CreateBoard}
                        />
                        <PrivateRoute
                            path="/board/:id"
                            exact
                            component={Board}
                        />
                    </Switch>
                </Layout>
            </Router>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
