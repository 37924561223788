import React, {useEffect, useState} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'

import {
    Field, 
    Label, 
    Input,
} from 'react-bulma-components/lib/components/form'
import Section from 'react-bulma-components/lib/components/section'
import Button from 'react-bulma-components/lib/components/button'
import Notification from 'react-bulma-components/lib/components/notification'
import Hero from 'react-bulma-components/lib/components/hero'
import Heading from 'react-bulma-components/lib/components/heading'

import {syn} from '@services/socket'
import ev from '@source/events'
import {quote} from '../inspiration'

function Login({
    dispatch, 
    greetingByServer, 
    login, 
    loginMessageHidden,
    token,
}) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [inspiration, setInspiration] = useState({})

    useEffect(() => {
        setInspiration(quote())
    }, [])

    function hideLoginMessage(e) {
        e.preventDefault()
        dispatch({ type: ev.ui.HIDE_LOGIN_MESSAGE })
    }

    function submit(e) {
        e.preventDefault()
        syn(ev.syn.LOGIN, {email, password})
    }

    if (token) {
        return <Redirect to="/board" />
    }

    return (
        <>
            <Hero color="info" gradient>
                <Hero.Body>
                    <Section>
                        <Heading size={3}>
                            Login
                        </Heading>
                        <Heading subtitle size={6}>
                            {inspiration.text}
                        </Heading>
                    </Section>
                </Hero.Body>
            </Hero>
            <Section>
                {greetingByServer || login.ack.msg ? (
                    <Notification
                        style={{
                            display: loginMessageHidden ? 'none' : 'block'
                        }}
                        color={login.error ? 'danger' : 'success'}
                    >
                        {login.ack.msg ? login.ack.msg : greetingByServer}
                        <Button onClick={hideLoginMessage} remove />
                    </Notification>
                ) : null}
                <form onSubmit={submit}>
                    <Field>
                        <Label>Email</Label>
                        <Input
                            color="danger"
                            autoComplete="username"
                            onChange={e => setEmail(e.target.value)}
                            type="email"
                            placeholder="royerbr@med.umich.edu"
                            value={email}
                        />
                    </Field>
                    <Field>
                        <Label>Password</Label>
                        <Input
                            color="danger"
                            onChange={e => setPassword(e.target.value)}
                            autoComplete="current-password"
                            type="password"
                            value={password}
                        />
                    </Field>
                    <Field>
                        <Button
                            id="submit"
                            type="submit"
                            fullwidth={true}
                            color="link"
                            rounded={true}
                        >
                            Login
                        </Button>
                    </Field>
                </form><br />
                <Field>
                    <Link to="/register">
                        <Button
                            fullwidth={true}
                            color="link"
                            rounded={true}
                            text={true}
                            inverted={true}
                        >
                            Request access
                        </Button>
                    </Link>
                </Field>
            </Section>
        </>
    )
}

const mapStateToProps = state => ({
    greetingByServer: state.socket.greetingByServer,
    loginMessageHidden: state.temp.loginMessageHidden,
    login: state.login,
    token: state.acl.token,
})

export default connect(mapStateToProps)(Login)
