
import React from 'react'
import Loader from 'react-bulma-components/lib/components/loader'

export default function Spinner({height, margin}) {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexGrow: 1,
                height: height ? height : '250px',
                margin: margin ? margin : 0,
            }}
        >
            <Loader
                style={{
                    width: 75,
                    height: 75,
                }}
            />
        </div>
    )
}