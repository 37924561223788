import React from 'react'
import {connect} from 'react-redux'
import Section from 'react-bulma-components/lib/components/section'
import Hero from 'react-bulma-components/lib/components/hero'
import Heading from 'react-bulma-components/lib/components/heading'
import {
    Control,
    Field as BulmaField,
    Label,
} from 'react-bulma-components/lib/components/form'
import Button from 'react-bulma-components/lib/components/button'
import Icon from 'react-bulma-components/lib/components/icon'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import makeAnimated from 'react-select/lib/animated'
import {Formik, Form, Field, FieldArray} from 'formik'
import {
    PathFancySelect,
    PathDropzone,
    PathInput,
    PathTextarea,
} from '../../forms/factory'

import {boardSchema} from '../../forms/schema'
import {ack, detach, syn} from '@services/socket'
import ev from '@source/events'

class CreateBoard extends React.Component {
    initialValues = {
        attachments: [],
        subspecialties: [],
        description: '',
        diagnosis: [
            {
                title: '',
                theory: '',
            },
        ],
    }

    componentWillUnmount() {
        detach(ev.ack.auth.CONSULT_SUCCESS)
    }

    componentDidMount() {
        ack(ev.ack.auth.CONSULT_SUCCESS, payload => {
            return (window.location = `/board/${payload.id}`)
        })
    }

    drawAddButton(helper) {
        return (
            <Control>
                <button
                    onClick={e => {
                        e.preventDefault()
                        helper.push({
                            title: '',
                            theory: '',
                        })
                    }}
                    className="button is-info"
                >
                    <FontAwesomeIcon
                        icon="plus"
                        style={{marginRight: '0.5em'}}
                    />{' '}
                    Theory
                </button>
            </Control>
        )
    }

    drawRemoveButton(helper, idx) {
        return (
            <button
                onClick={e => {
                    e.preventDefault()
                    helper.remove(idx)
                }}
                className="button"
            >
                <Icon>
                    <FontAwesomeIcon icon="trash" />
                </Icon>
            </button>
        )
    }

    decideRemoveTheory(helper, values, idx) {
        if (idx === 0 && values.diagnosis.length === 1) {
            return null
        }
        return <Control>{this.drawRemoveButton(helper, idx)}</Control>
    }

    decideAddTheory(helper, values, idx) {
        if (idx === 0 && values.diagnosis.length === 1) {
            return this.drawAddButton(helper)
        } else if (
            idx === values.diagnosis.length - 1 &&
            values.diagnosis.length !== 3
        ) {
            return this.drawAddButton(helper)
        }
    }

    renderTheories(values) {
        return (
            <FieldArray
                name="diagnosis"
                render={helper => this.addHelperToTheories(helper, values)}
            />
        )
    }

    addHelperToTheories(helper, values) {
        return (
            <div>
                {values.diagnosis && values.diagnosis.length > 0 ? (
                    values.diagnosis.map((field, idx) => (
                        <React.Fragment key={`diagArray.${idx}`}>
                            <BulmaField className="has-addons">
                                {this.decideRemoveTheory(helper, values, idx)}
                                <Control className="is-expanded">
                                    <Field
                                        type="text"
                                        id={`diagnosis.${idx}.title`}
                                        name={`diagnosis.${idx}.title`}
                                        component={PathInput}
                                        placeholder="Candidate Diagnosis"
                                    />
                                </Control>
                                {this.decideAddTheory(helper, values, idx)}
                            </BulmaField>
                            <Control>
                                <Field
                                    type="text"
                                    id={`diagnosis.${idx}.theory`}
                                    name={`diagnosis.${idx}.theory`}
                                    component={PathInput}
                                    placeholder="Reasoning"
                                />
                            </Control>
                            {values.diagnosis.length - 1 !== idx ? (
                                <br />
                            ) : null}
                        </React.Fragment>
                    ))
                ) : (
                    <Button
                        type="button"
                        onClick={e => {
                            e.preventDefault()
                            helper.push({
                                title: '',
                                theory: '',
                            })
                        }}
                    >
                        Click to add my first idea
                    </Button>
                )}
            </div>
        )
    }

    render() {
        return (
            <>
                <Hero color="info" gradient>
                    <Hero.Body>
                        <Section>
                            <Heading size={3}>Consult Request</Heading>
                            <Heading subtitle size={6}>
                                After providing up to three representational images 
                                and some additional details below, you'll be forwarded 
                                to a webpage that displays, in real time, your crowd-sourced 
                                results, as made possible by a global collective of 
                                live, on-line pathologists.  As results stream in, 
                                they will accrue instantaneously, without the need 
                                for refreshing of your browser page.
                            </Heading>
                        </Section>
                    </Hero.Body>
                </Hero>
                <Section>
                    <Formik
                        initialValues={this.initialValues}
                        validationSchema={boardSchema}
                        validateOnChange={true}
                        onSubmit={(values, {resetForm, setSubmitting}) => {
                            // setSubmitting(true)
                            syn(ev.syn.auth.SUBMIT_CONSULT, values)
                        }}
                    >
                        {({values, isSubmitting, errors, touched}) => (
                            <Form>
                                <BulmaField>
                                    <Field
                                        type="file"
                                        id="attachments"
                                        name="attachments"
                                        component={PathDropzone}
                                    />
                                </BulmaField>
                                <BulmaField>
                                    <Label>Subspecialties</Label>
                                    <Field
                                        id="subspecialties"
                                        name="subspecialties"
                                        type="subspecialties"
                                        closeMenuOnSelect={false}
                                        components={makeAnimated()}
                                        isMulti={true}
                                        options={this.props.subspecialties.map(
                                            spec => ({
                                                value: spec.id,
                                                label: spec.name,
                                            }),
                                        )}
                                        component={PathFancySelect}
                                    />
                                </BulmaField>
                                <BulmaField>
                                    <Label>
                                        <Heading size={4}>
                                            Brief Description
                                        </Heading>
                                        <Heading subtitle size={6}>
                                            Explain what we are looking at, the
                                            magnification scale, and any
                                            relevant questions.
                                        </Heading>
                                    </Label>
                                    <Control>
                                        <Field
                                            type="textarea"
                                            id="description"
                                            name="description"
                                            component={PathTextarea}
                                        />
                                    </Control>
                                </BulmaField>
                                <BulmaField>
                                    <Label>
                                        <Heading size={4}>
                                            Ideas on a diagnosis?
                                        </Heading>
                                        <Heading subtitle size={6}>
                                            Add them below as options for other
                                            users.
                                        </Heading>
                                    </Label>
                                    {this.renderTheories(values)}
                                </BulmaField>
                                <BulmaField>
                                    <Button
                                        fullwidth={true}
                                        color={
                                            Object.keys(errors).length &&
                                            Object.keys(touched).length
                                                ? 'danger'
                                                : 'info'
                                        }
                                        rounded={true}
                                        loading={
                                            isSubmitting ||
                                            (Object.keys(errors).length &&
                                            Object.keys(touched).length
                                                ? true
                                                : false)
                                        }
                                        id="submit"
                                        type="submit"
                                    >
                                        {Object.keys(errors).length &&
                                        Object.keys(touched).length ? (
                                            <>
                                                <small>
                                                    Mising required information!
                                                </small>
                                            </>
                                        ) : (
                                            'Submit request'
                                        )}
                                    </Button>
                                </BulmaField>
                            </Form>
                        )}
                    </Formik>
                </Section>
            </>
        )
    }
}

const mapStateToProps = state => ({
    subspecialties: state.acl.subspecialties,
    user: state.acl.details,
})

export default connect(mapStateToProps)(CreateBoard)
