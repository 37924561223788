export const defaultLoginState = {
    error: false,
    ack: {
        title: '',
        msg: '',
    },
}

export default (state = defaultLoginState, action) => {
    switch (action.type) {
        case 'ack/LOGOUT_SUCCESS': 
            return {
                ...state,
                error: false,
                ack: action.payload,
            }

        case 'ack/LOGIN_SUCCESS':
            return {
                ...state,
                error: false,
                ack: {
                    title: '',
                    msg: '',
                },
            }
        case 'ack/LOGIN_ERROR':
            return {
                ...state,
                error: true,
                ack: action.payload,
            }
        case 'CLEAR_LOGIN_MESSAGE':
            return {
                ...state,
                error: false,
                ack: {
                    title: '',
                    msg: '',
                },
            }
        default:
            return state
    }
}