import React from 'react'
import Hero from 'react-bulma-components/lib/components/hero'
import Heading from 'react-bulma-components/lib/components/heading'
import Section from 'react-bulma-components/lib/components/section'

export default function Index({history}) {
    function submitBoard() {
        return history.push('/board/create')
    }

    function viewBoards() {
        return history.push('/board')
    }

    return (
        <>
            <Hero size="medium" color="info" gradient>
                <Hero.Body>
                    <Section>
                        <Heading size={3}>HistoQuery</Heading>
                        <Heading subtitle size={6}>
<div className="content">
<p>HistoQuery is a tool for the sharing of anatomical pathology cases for educational purposes.  As a crowd-sourced Web-based resource, submitted cases, in the form of one of more microscopic fields of view along with a diagnostic question, are submitted by you and then immediately made available to a global collective of interested pathologists, residents and fellows, who will respond in real-time, providing diagnostic answers, rendered as a histogram.</p>
 
<p>This useful tool is brought to you by the University of Michigan Department of Pathology and the Division of Pathology Informatics.</p>
 
<p>First-time users will need to register and indicate their subspecialty or subspecialties of interest, to make use of this site. Verification typically takes less than 24 hours.  There is no fee to use this service.</p>
 
<p>Posting of protected health information on this site is strictly prohibited, as this is a tool for educational purposes only.</p>
</div>
                        </Heading>
                    </Section>
                </Hero.Body>
            </Hero>
            <Section>
                <div className="buttons">
                    <button
                        onClick={submitBoard}
                        className="button is-info is-large"
                    >
                        Submit a Board
                    </button>
                    <br />
                    <button
                        onClick={viewBoards}
                        className="button is-info is-large"
                    >
                        View Boards
                    </button>
                </div>
            </Section>
        </>
    )
}
