import React from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import Card from 'react-bulma-components/lib/components/card'
import Hero from 'react-bulma-components/lib/components/hero'
import Heading from 'react-bulma-components/lib/components/heading'
import Section from 'react-bulma-components/lib/components/section'
import Button from 'react-bulma-components/lib/components/button'
import ImageGallery from 'react-image-gallery'
import Spinner from '../../components/Spinner'
import InfiniteScroll from 'react-infinite-scroll-component'
import randomColor from 'randomcolor'
import formatRelative from 'date-fns/formatRelative'
import parseISO from 'date-fns/parseISO'

import {ackForever, detach, syn} from '@services/socket'
import ev from '@source/events'

class AllBoards extends React.Component {
    initialState = {
        count: 0,
        boards: [],
        lastIdInSet: 0,
        showImages: false,
    }

    state = this.initialState

    componentDidMount() {
        const subspecialty = this.getSubspecialty()
        ackForever(ev.ack.auth.REQUEST_ALL_BOARDS_SUCCESS, boards => {
            const currentBoards = this.state.boards.slice()
            const newBoards = boards.rows.slice()
            this.setState({
                boards: [...currentBoards, ...newBoards],
                count: boards.count,
                lastIdInSet: boards.lastIdInSet,
            })
        })
        this.setColor(subspecialty)
        this.getMoreBoards()
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({ ...this.initialState }, this.getMoreBoards)
        }
    }

    componentWillUnmount() {
        detach(ev.ack.auth.REQUEST_ALL_BOARDS_SUCCESS)
    }

    getSubspecialty = (humanReadable = false) => {
        let {subspecialty} = this.props.match.params
        if (this.props.match.params.hasOwnProperty('subspecialty')) {
            if (!subspecialty) {
                subspecialty = humanReadable 
                    ? 'My Subspecialties' 
                    : this.props.user.id
            }
        }
        return subspecialty || 'All Boards'
    }

    getMoreBoards = () => {
        const subspecialty = this.getSubspecialty()
        this.setColor(subspecialty)
        syn(ev.syn.auth.REQUEST_ALL_BOARDS, {
            subspecialty,
            lastIdInSet: this.state.lastIdInSet,
        })
    }

    setColor = subspecialty => {
        this.color = randomColor({
            hue: '#00274C',
            luminosity: 'dark',
            format: 'rgb',
            seed: subspecialty,
        })
        this.forceUpdate()
    }
    
    toggleImages = () => {
        this.setState(state => ({
            showImages: !state.showImages,
        }))
    }

    refreshBoards = i => {
        window.location.reload()
    }

    render() {
        const subspecialty = this.getSubspecialty(true)
        const {user, history} = this.props
        return (
            <React.Fragment>
                <Hero style={{ backgroundColor: this.color, color: '#eee' }} gradient>
                    <Hero.Body>
                        <Section>
                            <Heading style={{
                                color: '#eee',
                            }} size={3}>{subspecialty}</Heading>
                            <Heading style={{
                                color: '#eee',
                            }} subtitle size={6}>
                                Welcome, {user.name}. Get started by browsing
                                some of the submissions below or creating your
                                own!
                                <br />
                                <br />
                                <Link to="/board/create">
                                    <Button color="info">
                                        Submit an image
                                    </Button>
                                </Link>
                            </Heading>
                        </Section>
                    </Hero.Body>
                </Hero>
                {this.state.boards ? (
                    <InfiniteScroll
                        dataLength={this.state.boards.length}
                        hasMore={this.state.count !== this.state.boards.length}
                        loader={<Spinner height="95px" margin="0 0 2em" />}
                        scrollThreshold={1}
                        next={this.getMoreBoards}
                        refreshFunction={this.refreshBoards}
                        endMessage={
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    margin: '2em'
                                }}
                            >
                                <b>{`There are no more boards listed for ${subspecialty}.`}</b>
                            </div>
                        }
                    >
                        <Section>
                            <Button
                                fullwidth={true}
                                color={
                                    this.state.showImages
                                        ? 'danger'
                                        : 'info'
                                }
                                rounded={true}
                                onClick={this.toggleImages}
                            >
                                {`${this.state.showImages ? 'Hide' : 'Show'} Images`}
                            </Button><br />
                            {this.state.boards.map(board => (
                                <React.Fragment key={`board_${board.id}`}>
                                    <Card
                                        style={{cursor: 'pointer'}}
                                        onClick={() =>
                                            history.push(`/board/${board.id}`)
                                        }
                                    >
                                        <Card.Header>
                                            <p className="card-header-title">
                                                {board.description 
                                                    ? board.description 
                                                    : `Uploaded ${formatRelative(parseISO(board.created_at), new Date())}`}
                                            </p>
                                        </Card.Header>
                                            {this.state.showImages ? (
                                                <Card.Content>
                                                    <ImageGallery
                                                        showThumbnails={false}
                                                        showFullscreenButton={false}
                                                        showPlayButton={false}
                                                        items={board.uploads.map(
                                                            upload => ({
                                                                original: upload.path.replace(
                                                                    '/usr/src/app/client/public',
                                                                    '',
                                                                ),
                                                                thumbnail: upload.path.replace(
                                                                    '/usr/src/app/client/public',
                                                                    '',
                                                                ),
                                                            }),
                                                        )}
                                                    />
                                                </Card.Content>
                                            ) : null}
                                    </Card>
                                    <br />
                                </React.Fragment>
                            ))}
                        </Section>
                    </InfiniteScroll>
                ) : (
                    <Spinner />
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.acl.details,
})

export default connect(mapStateToProps)(AllBoards)
