import React from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'

import {
    Field, 
} from 'react-bulma-components/lib/components/form'
import Section from 'react-bulma-components/lib/components/section'
import Button from 'react-bulma-components/lib/components/button'
import Hero from 'react-bulma-components/lib/components/hero'
import Heading from 'react-bulma-components/lib/components/heading'

import { ackForever, detach, syn } from '@services/socket'
import ev from '@source/events'

class Demo extends React.Component {

    state = {
        demoMode: true,
    }

    componentDidMount() {
        syn(ev.syn.REQUEST_DEMO_MODE_STATUS)
        ackForever(ev.ack.REQUEST_DEMO_MODE_STATUS_SUCCESS, status => {
            this.setState({ demoMode: status.value === 'true' })
        })
    }

    componentWillUnmount() {
        detach(ev.ack.REQUEST_DEMO_MODE_STATUS_SUCCESS)
    }

    submit(e) {
        e.preventDefault()
        syn(ev.syn.DEMO_LOGIN)
    }

    render() {
        if (!this.state.demoMode) {
            return <Redirect to="/" />
        }
        if (this.props.token) {
            return <Redirect to="/board" />
        }
    
        return (
            <>
                <Hero color="info" gradient>
                    <Hero.Body>
                        <Section>
                            <Heading size={3}>
                                Demo
                            </Heading>
                        </Section>
                    </Hero.Body>
                </Hero>
                <Section>
                    <form onSubmit={this.submit}>
                        <Field>
                            <Button
                                id="submit"
                                type="submit"
                                fullwidth={true}
                                color="link"
                                rounded={true}
                            >
                                Login
                            </Button>
                        </Field>
                    </form>
                </Section>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.acl.token,
})

export default connect(mapStateToProps)(Demo)
