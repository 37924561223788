import 'react-photoswipe/lib/photoswipe.css'
import 'react-image-gallery/styles/css/image-gallery.css'

import React, {Component} from 'react'
import {connect} from 'react-redux'
import Hero from 'react-bulma-components/lib/components/hero'
import Heading from 'react-bulma-components/lib/components/heading'
import Section from 'react-bulma-components/lib/components/section'
import Card from 'react-bulma-components/lib/components/card'
import Tag from 'react-bulma-components/lib/components/tag'
import Spinner from '../../components/Spinner'
import UserPost from '../../containers/UserPost'
import Plot from '../../components/Plot'
import GuestTheoryForm from '../../components/GuestTheoryForm'
import {ack, detach, syn} from '@services/socket'
import ev from '@source/events'
import {PhotoSwipe} from 'react-photoswipe'
import ImageGallery from 'react-image-gallery'

class Board extends Component {
    state = {
        error: false,
        lightbox: false,
    }

    componentDidMount() {
        const segment = window.location.pathname.split('/')
        const id = parseInt(segment.pop() || segment.pop(), 10)
        syn(ev.syn.auth.REQUEST_BOARD, {id})
        ack(ev.ack.auth.REQUEST_BOARD_ERROR, error => {
            this.setState({error})
        })
    }

    componentWillUnmount() {
        detach(ev.syn.auth.REQUEST_BOARD_ERROR)
    }

    closeLightbox = () => {
        this.setState({lightbox: false})
    }

    render() {
        const {details, userVote} = this.props.board
        if (!details) {
            return
        }
        const {uploads, options, subspecialties} = details

        if (this.state.error) {
            return (
                <>
                    <Hero size="medium" color="warning" gradient>
                        <Hero.Body>
                            <Section>
                                <Heading>{this.state.error.title}</Heading>
                            </Section>
                        </Hero.Body>
                    </Hero>
                    <Section>{this.state.error.msg}</Section>
                </>
            )
        }

        if (!uploads || !options) {
            return <Spinner />
        }

        return uploads.length && options.length ? (
            <>
                <Hero color="info" gradient>
                    <Hero.Body>
                        <Section>
                            <Heading size={3}>Board #{details.id}</Heading>
                            <Heading size={6}>
                                Subspecialties:{' '}
                                {subspecialties
                                    .map(subspec => subspec.name)
                                    .join(', ')}
                            </Heading>
                        </Section>
                    </Hero.Body>
                </Hero>
                <Section>
                    <Card>
                        <ImageGallery
                            ref={i => (this._imageGallery = i)}
                            renderFullscreenButton={(onClick, isFullscreen) => {
                                return (
                                    <button
                                        type="button"
                                        className={`image-gallery-fullscreen-button${
                                            isFullscreen ? ' active' : ''
                                        }`}
                                        onClick={e => {
                                            this.setState({
                                                lightbox: true,
                                            })
                                        }}
                                    />
                                )
                            }}
                            items={uploads.map(item => ({
                                original: item.path.replace(
                                    '/usr/src/app/client/public',
                                    '',
                                ),
                                thumbnail: item.path.replace(
                                    '/usr/src/app/client/public',
                                    '',
                                ),
                            }))}
                        />
                        <PhotoSwipe
                            options={{
                                index: this._imageGallery
                                    ? this._imageGallery.getCurrentIndex()
                                    : 0,
                            }}
                            isOpen={this.state.lightbox}
                            onClose={this.closeLightbox}
                            onClickPrev={() => {}}
                            onClickNext={() => {}}
                            items={uploads.map(item => ({
                                src: item.path.replace(
                                    '/usr/src/app/client/public',
                                    '',
                                ),
                                w: item.width,
                                h: item.height,
                            }))}
                        />
                        <Card.Content>
                            <UserPost board={this.props.board.details} />
                            <Tag
                                color="info"
                                className="is-pulled-right"
                                style={{marginBottom: '1em'}}
                            >
                                Posted:{' '}
                                {new Date(details.created_at).toLocaleString()}
                            </Tag>
                            <Plot options={options} userVote={userVote} />
                            <GuestTheoryForm
                                board={details}
                                user={this.props.user}
                            />
                        </Card.Content>
                    </Card>
                </Section>
            </>
        ) : (
            <Spinner />
        )
    }
}

const mapStateToProps = state => ({
    board: state.board,
    user: state.acl.details,
})

export default connect(mapStateToProps)(Board)
