import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import {connect} from 'react-redux'

export function AdminRoute({admin, component: Component, token, ...rest}) {
    return (
        <Route
            {...rest}
            render={props =>
                token && admin ? <Component {...props} /> : <Redirect back />
            }
        />
    )
}

const mapStateToProps = state => ({
    token: state.acl.token,
    admin: state.acl.details.admin,
})

export default connect(mapStateToProps)(AdminRoute)
