import React from 'react'
import {connect} from 'react-redux'
import Button from 'react-bulma-components/lib/components/button'
import ev from '@source/events'
import {syn} from '@services/socket'
import Spinner from '../../components/Spinner'

class EditSubspecialties extends React.Component {

    subscribe(user, subspec) {
        syn(ev.syn.auth.REQUEST_SUBSCRIPTION, {
            user,
            subspec,
        })
    }

    renderSubscriptionBtn(user, subspec) {
        // return user.subspecialties.includes(subspec.name)
        const subscription = user.subspecialties.find(subspecs => {
            return subspecs.name === subspec.name
        })
        if (subscription && subscription.name) {
            return <Button color="danger" onClick={() => this.subscribe(user, subspec)}>Unsubscribe</Button>
        }
        return <Button onClick={() => this.subscribe(user, subspec)}>Subscribe</Button>
    }

    render() {
        const {subspecialties, user} = this.props
        if (!subspecialties.length) {
            return <Spinner />
        }

        return (
            <>
                <h1 className="is-size-3">Current Subscribed Subspecialties</h1>
                <ul>
                    {user.subspecialties.map(subspec => (
                        <li>{subspec.name}</li>
                    ))}
                </ul>
                <br />
                <h1 className="is-size-3">Subspecialties Subscription Area</h1>
                <table>
                    <thead>
                        <tr>
                            <th>Subspecialty</th>
                            <th>Subscribe</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subspecialties.map(subspec => (
                            <tr>
                                <td>{subspec.name}</td>
                                <td>
                                    {this.renderSubscriptionBtn(user, subspec)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        )
    }
}

const mapStateToProps = state => ({
    board: state.board,
    user: state.acl.details,
    subspecialties: state.acl.subspecialties,
})

export default connect(mapStateToProps)(EditSubspecialties)
