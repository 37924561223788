import {createStore} from 'redux'
import reducers from './reducers'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import io from 'socket.io-client'

const socketConnection = io('/')

var onevent = socketConnection.onevent
socketConnection.onevent = function(packet) {
    var args = packet.data || []
    onevent.call(this, packet) // original call
    packet.data = ['*'].concat(args)
    onevent.call(this, packet) // additional call to catch-all
}

export default function configureStore() {
    const persistConfig = {
        key: 'root',
        storage,
        blacklist: ['temp'],
    }

    const persistedReducer = persistReducer(persistConfig, reducers)

    const store = createStore(
        persistedReducer,
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__(),
    )
    const persistor = persistStore(store)

    const configureSocket = dispatch => {
        const localEvents = [
            `ack/GET_REGISTRATION_DATA_SUCCESS`,
            `ack/CHANGE_APPROVAL_STATUS_SUCCESS`,
            `ack/REQUEST_UNAPPROVED_USERS_SUCCESS`,
            `ack/REQUEST_APPLICATION_SETTINGS_SUCCESS`,
            `ack/REQUEST_ALL_BOARDS_SUCCESS`,
            `ack/REGISTRATION_SUCCESS`,
            `ack/REQUEST_BOARD_ERROR`,
            `ack/REQUEST_DEMO_MODE_STATUS_SUCCESS`,
        ]
        socketConnection.on('*', function(type, payload) {
            if (localEvents.includes(type)) {
                console.log(`Local event: ${type}`, payload)
                return
            }
            console.log(`Global event: ${type}`, payload)

            dispatch({type, payload})
        })
        socketConnection.on('error', socketError => {
            console.error(`Socket Error`, socketError)
        })
        return socketConnection
    }

    const socket = configureSocket(store.dispatch)

    return {socket, persistor, store}
}
