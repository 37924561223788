import React from 'react'
import {
    Help,
    Textarea,
    Input,
    Select,
} from 'react-bulma-components/lib/components/form'
import {default as ReactSelect} from 'react-select'
import FileUploader from '../../components/FileUploader'
import { getIn } from 'formik'

const hasErrors = (form, field) => {
    if (form.touched[field.name] && form.errors[field.name]) {
        return form.errors[field.name]
    }
    const error = getIn(form.errors, field.name)
    const touch = getIn(form.touched, field.name)
    return touch && error
}

const componentClass = (form, field) => {
    return hasErrors(form, field) ? 'danger' : null
}

const make = (Component) => ({ children, field, ...props }) => {
    const error = hasErrors(props.form, field)
    const color = componentClass(props.form, field)
    return (
        <>
            <Component color={color} {...field} {...props}>
                {children ? children : null}
            </Component>
            {error !== undefined && (
                <Help color="danger" style={{ fontWeight: 'bold' }}>{error}</Help>
            )}
        </>
    )
}

const makeFancy = (Component) => ({ children, field, ...props }) => {
    const error = hasErrors(props.form, field)
    return (
        <>
            <Component 
                {...field} 
                {...props}
                onChange={value => {
                    props.form.setFieldValue(field.name, value)
                }}
            >
                {children ? children : null}
            </Component>
            {error !== undefined && (
                <Help color="danger" style={{ fontWeight: 'bold' }}>{error}</Help>
            )}
        </>
    )
}

export const PathDropzone = make(FileUploader)
export const PathTextarea = make(Textarea)
export const PathInput = make(Input)
export const PathSelect = make(Select)
export const PathFancySelect = makeFancy(ReactSelect)