import React from 'react'
import Button from 'react-bulma-components/lib/components/button'

export default class DemoMode extends React.Component {
    render() {
        const {demoMode} = this.props
        const demoModeEnabled = (demoMode.value === 'true')
        return (
            <Button
                fullwidth={true}
                color={
                    demoModeEnabled
                        ? 'info'
                        : 'danger'
                }
                rounded={true}
                onClick={this.props.toggleDemoMode}
            >
                {`${demoModeEnabled ? 'Disable' : 'Enable'} Demo Mode`}
            </Button>
        )
    }
}
