import React from 'react'
import Section from 'react-bulma-components/lib/components/section'
import {
    Control,
    Field as BulmaField,
    Label,
} from 'react-bulma-components/lib/components/form'
import Button from 'react-bulma-components/lib/components/button'
import Notification from 'react-bulma-components/lib/components/notification'
import Hero from 'react-bulma-components/lib/components/hero'
import Heading from 'react-bulma-components/lib/components/heading'
import {Formik, Form, Field} from 'formik'
import Spinner from '../components/Spinner'
import {
    PathInput,
    PathFancySelect,
    PathSelect,
    PathTextarea,
} from '../forms/factory'
import makeAnimated from 'react-select/lib/animated'
import {registerSchema} from '../forms/schema'
import {ack, ackForever, detach, syn} from '@services/socket'
import ev from '@source/events'

export default class Register extends React.Component {
    state = {
        schools: [],
        subspecialties: [],
        response: false,
    }

    initialValues = {
        bio: '',
        name: '',
        school_id: '',
        subspecialties: [],
        email: '',
        email_verify: '',
        password: '',
        password_verify: '',
    }

    componentDidMount() {
        syn(ev.syn.GET_REGISTRATION_DATA)
        ack(
            ev.ack.GET_REGISTRATION_DATA_SUCCESS,
            ({schools, subspecialties}) => {
                this.setState({schools, subspecialties})
            },
        )
        ackForever(ev.ack.REGISTRATION_SUCCESS, response => {
            this.setState({response: {...response, error: false}})
        })
        ackForever(ev.ack.REGISTRATION_ERROR, response => {
            this.setState({response: {...response, error: true}})
        })
    }

    componentWillUnmount() {
        detach(ev.ack.GET_REGISTRATION_DATA_SUCCESS)
        detach(ev.ack.REGISTRATION_SUCCESS)
        detach(ev.ack.REGISTRATION_ERROR)
    }

    render() {
        const {response, schools, subspecialties} = this.state
        return schools.length && subspecialties.length ? (
            <>
                <Hero color="info" gradient>
                    <Hero.Body>
                        <Section>
                            <Heading size={3}>Request Access</Heading>
                            <Heading subtitle size={6}>
                                Please fill out the form below. The process of
                                selection is manual so please give us some time
                                to review your request.
                            </Heading>
                        </Section>
                    </Hero.Body>
                </Hero>
                <Section>
                    <Formik
                        initialValues={this.initialValues}
                        validationSchema={registerSchema}
                        onSubmit={(values, {resetForm, setSubmitting}) => {
                            setSubmitting(true)
                            resetForm(this.initialValues)
                            const {
                                bio,
                                name,
                                school_id,
                                subspecialties,
                                email,
                                password,
                            } = values
                            syn(ev.syn.REGISTER, {
                                bio,
                                name,
                                school_id,
                                subspecialties,
                                email,
                                password,
                            })
                        }}
                    >
                        {({values, isSubmitting, handleSubmit}) => (
                            <>
                                <Form>
                                    <BulmaField>
                                        <Label>Your name</Label>
                                        <Field
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="Brian Royer"
                                            component={PathInput}
                                        />
                                    </BulmaField>
                                    <BulmaField>
                                        <Control className="is-expanded">
                                            <Label>School</Label>
                                            <Field
                                                type="select"
                                                id="school_id"
                                                name="school_id"
                                                className="is-fullwidth"
                                                component={PathSelect}
                                            >
                                                <option value="">Select</option>
                                                {schools.map(school => {
                                                    return (
                                                        <option
                                                            key={`school.${
                                                                school.id
                                                            }`}
                                                            value={school.id}
                                                        >
                                                            {school.school}
                                                        </option>
                                                    )
                                                })}
                                            </Field>
                                        </Control>
                                    </BulmaField>
                                    <BulmaField>
                                        <Label>Subspecialties</Label>
                                        <Field
                                            id="subspecialties"
                                            name="subspecialties"
                                            type="subspecialties"
                                            closeMenuOnSelect={false}
                                            components={makeAnimated()}
                                            isMulti={true}
                                            options={this.state.subspecialties}
                                            component={PathFancySelect}
                                        />
                                    </BulmaField>
                                    <BulmaField>
                                        <Label>About yourself</Label>
                                        <Field
                                            id="bio"
                                            name="bio"
                                            type="bio"
                                            component={PathTextarea}
                                            placeholder="Please provide a brief introduction and a little about yourself to help the approver."
                                        />
                                    </BulmaField>
                                    <BulmaField>
                                        <Label>Email</Label>
                                        <Field
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="current_email"
                                            placeholder="royerbr@med.umich.edu"
                                            component={PathInput}
                                        />
                                    </BulmaField>
                                    <BulmaField>
                                        <Label>Verify Email</Label>
                                        <Field
                                            id="email_verify"
                                            name="email_verify"
                                            type="email"
                                            autoComplete="current_email"
                                            placeholder="royerbr@med.umich.edu"
                                            component={PathInput}
                                        />
                                    </BulmaField>
                                    <BulmaField>
                                        <Label>Password</Label>
                                        <Field
                                            id="password"
                                            name="password"
                                            type="password"
                                            autoComplete="new_password"
                                            component={PathInput}
                                        />
                                    </BulmaField>
                                    <BulmaField>
                                        <Label>Verify Password</Label>
                                        <Field
                                            id="password_verify"
                                            name="password_verify"
                                            type="password"
                                            autoComplete="new_password"
                                            component={PathInput}
                                        />
                                    </BulmaField>
                                    {response ? (
                                        <Notification
                                            color={
                                                response.error
                                                    ? 'danger'
                                                    : 'primary'
                                            }
                                            style={{
                                                marginTop: '1em',
                                            }}
                                        >
                                            {response.msg}
                                        </Notification>
                                    ) : null}
                                    <Button
                                        fullwidth={true}
                                        disabled={isSubmitting}
                                        id="submit"
                                        type="submit"
                                        color="link"
                                        rounded={true}
                                    >
                                        Request Access
                                    </Button>
                                </Form>
                            </>
                        )}
                    </Formik>
                </Section>
            </>
        ) : (
            <Spinner />
        )
    }
}
