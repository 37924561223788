import * as yup from 'yup'

export const boardSchema = yup.object().shape({
    attachments: yup
        .array()
        .min(
            1,
            'Please attach a Field Of View (FOV) of your microscope image for Pathologists to review.',
        )
        .required(),
    diagnosis: yup
        .array()
        .of(
            yup.object().shape({
                title: yup
                    .string()
                    .min(3)
                    .max(80)
                    .required('Please choose a title for your theory.'),
                theory: yup
                    .string()
                    .min(6)
                    .max(1024)
                    .required('Please explain some reasoning or details.'),
            }),
        )
        .required(),
})

export const registerSchema = yup.object().shape({
    name: yup
        .string()
        .min(5, 'A full name must consist of more than 6 characters.')
        .required('Your full name is required'),
    school_id: yup
        .string()
        .min(1, 'Please choose a school from the list')
        .required('Please choose a school from the list'),
    subspecialties: yup
        .array()
        .min(1, 'Please choose at least one subspecialty.'),
    bio: yup
        .string()
        .min(12, 'Please be more descriptive here.')
        .required('An introduction/about yourself is required.'),
    email: yup
        .string()
        .email('Invalid email address')
        .required('Email is required'),
    email_verify: yup
        .string()
        .oneOf([yup.ref('email')], 'Emails must match')
        .required('Email verification is required'),
    password: yup
        .string()
        .min(6)
        .required('Password is required'),
    password_verify: yup
        .string()
        .oneOf([yup.ref('password')], 'Passwords must match')
        .required('Password verification is required'),
})

export const addTheorySchema = yup.object().shape({
    title: yup
        .string()
        .min(3)
        .max(80)
        .required('Please choose a title for your theory.'),
    theory: yup
        .string()
        .min(6)
        .max(1024)
        .required('Please explain some reasoning or details.'),
})
