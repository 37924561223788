class Registry {
    register(type, value) {
        this[type] = value
    }

    get(type) {
        return this[type]
    }
}

export default new Registry()
