import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faMicroscope,
    faMinus,
    faPlus,
    faTrash,
    faUpload,
    faPooStorm,
    faSignOutAlt,
    faCog,
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faMicroscope,
    faMinus,
    faPlus,
    faTrash,
    faUpload,
    faPooStorm,
    faSignOutAlt,
    faCog,
)
