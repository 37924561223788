import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Navbar from 'react-bulma-components/lib/components/navbar'
import Icon from 'react-bulma-components/lib/components/icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { syn } from '@services/socket'
import ev from '@source/events'

function Layout({ admin, children, history, subspecialties, token }) {
    const [open, setOpen] = useState(false)

    function navigate(location) {
        setOpen(false)
        history.push(location)
    }

    function logout() {
        syn(ev.syn.LOGOUT)
    }

    const menu = token ? (
        <>
            <Navbar.Item onClick={() => navigate('/board/create')}>
                Submit a Consult Request
            </Navbar.Item>
            <div className="navbar-item has-dropdown is-hoverable">
                <span className="navbar-link">Boards</span>
                <div className="navbar-dropdown is-right">
                <Navbar.Item onClick={() => navigate(`/board`)}>
                    All Boards
                </Navbar.Item>
                <Navbar.Item onClick={() => navigate(`/board/subspecialty`)}>
                    My Subspecialties
                </Navbar.Item>
                <hr className="navbar-divider" />
                {subspecialties.map(subspec => (
                    <Navbar.Item key={`subspec_${subspec.name}`} onClick={() => navigate(`/board/subspecialty/${encodeURIComponent(subspec.name)}`)}>
                        {subspec.name}
                    </Navbar.Item>
                ))}
                </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
                <span className="navbar-link">My Account</span>
                <div className="navbar-dropdown is-right">
                    <Navbar.Item onClick={() => navigate('/settings')}>
                        <FontAwesomeIcon
                            style={{ marginRight: '0.5em' }}
                            icon="cog"
                        />
                        My Settings
                    </Navbar.Item>
                    {admin && (
                        <Navbar.Item onClick={() => navigate('/admin')}>
                            <FontAwesomeIcon
                                style={{ marginRight: '0.5em' }}
                                icon="poo-storm"
                            />
                            Administrator
                        </Navbar.Item>

                    )}
                    <hr className="navbar-divider" />
                    <Navbar.Item onClick={() => logout()}>
                        <FontAwesomeIcon
                            style={{ marginRight: '0.5em' }}
                            icon="sign-out-alt"
                        />
                        Logout
                    </Navbar.Item>
                </div>
            </div>
        </>
    ) : (
            <>
                <Navbar.Item onClick={() => navigate('/login')}>Login</Navbar.Item>
                <Navbar.Item onClick={() => navigate('/register')}>
                    Request Access
                </Navbar.Item>
            </>
        )

    return (
        <div>
            <Navbar active={open} color="white" fixed="top" transparent={true}>
                <Navbar.Brand>
                    <Navbar.Item renderas="a" onClick={() => navigate('/')}>
                        <Icon>
                            <FontAwesomeIcon icon="microscope" />
                        </Icon>
                        <div style={{ paddingLeft: '0.5em' }}>HistoQuery</div>
                    </Navbar.Item>
                    <Navbar.Burger
                        active={open.toString()}
                        onClick={() => {
                            setOpen(!open)
                        }}
                    />
                </Navbar.Brand>
                <Navbar.Menu active={open.toString()}>
                    <Navbar.Container>
                        <Navbar.Item onClick={() => navigate('/')}>
                            Home
                        </Navbar.Item>
                    </Navbar.Container>
                    <Navbar.Container position="end">{menu}</Navbar.Container>
                </Navbar.Menu>
            </Navbar>
            {children}
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.acl.details.admin,
    token: state.acl.token,
    subspecialties: state.acl.subspecialties,
})

export default connect(mapStateToProps)(withRouter(Layout))
