export const defaultRoomState = {
    current: '',
    users: [],
}

export default (state = defaultRoomState, action) => {
    switch (action.type) {
        case 'ack/LOGOUT_SUCCESS':
            return defaultRoomState
        default:
            return state
    }
}