export const defaultAclState = {
    details: {},
    token: '',
    subspecialties: [],
}

export default (state = defaultAclState, action) => {
    switch (action.type) {
        case 'ack/REQUEST_SUBSCRIPTION_SUCCESS':
            return {
                ...state,
                details: {
                    ...state.details,
                    subspecialties: action.payload.subspecialties,
                }
            }
        case 'ack/LOGOUT_SUCCESS':
            return defaultAclState
        case 'ack/LOGIN_SUCCESS':
            return {
                ...state,
                details: action.payload.details,
                token: action.payload.token,
                subspecialties: action.payload.subspecialties,
            }
        default:
            return state
    }
}