import React from 'react'
import {
    Control,
    Field as BulmaField,
    Label,
} from 'react-bulma-components/lib/components/form'
import Button from 'react-bulma-components/lib/components/button'
import {Formik, Form, Field} from 'formik'
import {PathInput, PathTextarea} from '../forms/factory'
import {addTheorySchema} from '../forms/schema'
import {syn} from '@services/socket'
import ev from '@source/events'
// import {empty} from '../libs'

class GuestTheoryForm extends React.Component {
    initialValues = {
        title: '',
        theory: '',
    }

    render() {
        const {board, user} = this.props

        // If the board belongs to the user, don't show this form.
        if (board.user_id === user.id) {
            return null
        }

        const userHasOption = board.options.filter(
            option => option.user_id === user.id,
        )

        // If the user already has an option on this board, don't show this form.
        if (userHasOption.length) {
            return null
        }

        return (
            <Formik
                initialValues={this.initialValues}
                validationSchema={addTheorySchema}
                validateOnChange={true}
                onSubmit={(values, {resetForm, setSubmitting}) => {
                    setSubmitting(true)
                    resetForm(this.initialValues)
                    syn(ev.syn.auth.SUBMIT_VOTE_OPTION, {
                        values,
                        board_id: board.id,
                    })
                }}
            >
                {({values, isSubmitting, errors, touched}) => (
                    <Form>
                        <p>
                            To submit your own theory to this board, use the
                            form below. You may only add one theory per board.
                        </p>
                        <br />
                        <BulmaField>
                            <Label>Candidate Diagnosis</Label>
                            <Control>
                                <Field
                                    type="text"
                                    id="title"
                                    name="title"
                                    component={PathInput}
                                />
                            </Control>
                        </BulmaField>
                        <BulmaField>
                            <Label>Reasoning</Label>
                            <Control>
                                <Field
                                    id="theory"
                                    name="theory"
                                    component={PathTextarea}
                                />
                            </Control>
                        </BulmaField>
                        <BulmaField>
                            <Button
                                fullwidth={true}
                                color={
                                    Object.keys(errors).length &&
                                    Object.keys(touched).length
                                        ? 'danger'
                                        : 'info'
                                }
                                rounded={true}
                                disabled={
                                    isSubmitting ||
                                    (Object.keys(errors).length &&
                                    Object.keys(touched).length
                                        ? true
                                        : false)
                                }
                                id="submit"
                                type="submit"
                            >
                                {Object.keys(errors).length &&
                                Object.keys(touched).length ? (
                                    <>
                                        <small>
                                            Mising required information!
                                        </small>
                                    </>
                                ) : (
                                    'Submit theory (one per guest)'
                                )}
                            </Button>
                        </BulmaField>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default GuestTheoryForm
