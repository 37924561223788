export const defaultSocketState = {
    greetingByServer: '',
}

export default (state = defaultSocketState, action) => {
    switch (action.type) {
        case 'ack/WELCOME':
            return {
                ...state,
                greetingByServer: action.payload,
            }
        default:
            return state
    }
}