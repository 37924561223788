import React from 'react'
import Dropzone from 'react-dropzone-uploader'

export default function CustomPreview(props) {
    const onChangeStatus = (file, action, files) => {
        if (action === "done") {
            props.form.setFieldValue('attachments', files)
        }
    }

    const hasErrors = () => {
        return props.form.errors.attachments !== undefined
            && props.form.touched.attachments !== undefined ? true : false
    }

    return (
        <Dropzone
            {...props}
            styles={{
                dropzone: {
                    border: hasErrors() ? '2px solid #ff3860' : '',
                },
                inputLabel: {
                    color: hasErrors() ? '#ff3860' : '',
                }
            }}
            accept="image/*"
            onChangeStatus={onChangeStatus}
            // 50 MB
            maxSizeBytes={5e+7} 
            maxFiles={3}
            inputContent="Drag or upload up to 3 images."
            inputWithFilesContent={files => `${3 - files.length} more`}
        />
    )
}
